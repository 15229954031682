<template>
  <div class="ct-table-header-cell d-flex align-center" @click="handleSort">
    <div class="select-all" v-if="selectAll" @click.stop>
      <ct-checkbox dense hide-details @change="emitSelectAll" v-model="allSelected"></ct-checkbox>
    </div>
    <div class="t-14 font-weight-semibold">{{title}}</div>
    <div class="sort-manager" v-if="sortable">
      <ct-icon>mdi-arrow-down</ct-icon>
    </div>
    <div class="help-text" v-if="activeHeaderObject.helpText">
      <v-tooltip bottom :max-width="'40vw'" :z-index="999">
        <template v-slot:activator="{ on }">
          <div class="icon" v-on="on">?</div>
        </template>
        <span>{{ activeHeaderObject.helpText }}</span>
      </v-tooltip>
    </div>
  </div>
</template>
<script>
import CtCheckbox from '../../Checkbox/CtCheckbox'
import CtIcon from '../../Icon/CtIcon'

export default {
  name: 'CtCellHeaderTemplate',

  components: {
    CtIcon,
    CtCheckbox
  },

  props: {
    field: String,
    title: String,
    sortable: [Boolean, Object],
    headerItems: {
      type: Array,
      default: () => ([])
    },
    dataItems: {
      type: Array
    },
    selectedField: {
      type: String
    },
    selectAll: {
      // Enable select all checkbox on header
      type: Boolean,
      default: false
    }
  },

  methods: {
    emitSelectAll (e) {
      this.$emit('selectAll', {
        selection: e,
        item: this.field
      })
    },

    handleSort (e) {
      if (this.sortable) {
        this.$emit('sort', {
          field: this.field
        })
      }
    }
  },

  computed: {
    activeHeaderObject () {
      return this.headerItems.find(item => item.field === this.field) || {}
    },

    allSelected: {
      get () {
        return this.selectedFieldsCount === this.dataItems.length
      },
      set (val) {}
    },

    selectedFieldsCount () {
      if (this.dataItems.length > 0) {
        return this.dataItems.filter(item => !!item[this.selectedField]).length
      }
      return 0
    }
  }
}
</script>
<style lang="scss" scoped>
.ct-table-header-cell {
  .select-all {
    display: none;
    .v-input--selection-controls {
      margin: 0;
    }
  }
  .help-text {
    margin-left: auto;

    .icon {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background-color: #63698F;
      border-radius: 50%;
    }
  }

  .sort-manager {
    opacity: 0;
  }
}

</style>
