<template>
  <td :class="className" @click="clickHandler">
    <div class="cell-item d-flex align-center">
      <ct-checkbox v-if="showSelectBox" dense hide-details @change="emitSelection" v-model="selected"></ct-checkbox>
      <div class="cell-content" ref="cellContent" @mouseover="setContentStatus">
        <ct-tooltip bottom :disabled="!contentTruncated">
          <template v-slot:activator="{ on }">
            <span v-on="on">{{ getNestedValue(field, dataItem)}}</span>
          </template>
          <span>{{ getNestedValue(field, dataItem)}}</span>
        </ct-tooltip>
      </div>
    </div>
  </td>
</template>
<script>
import CtCheckbox from '../../Checkbox/CtCheckbox'
import CtTooltip from '../../Tooltip/CtTooltip'
export default {
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String,
    selectAll: Boolean,
    selectedField: String,
    expandField: String
  },

  components: {
    CtCheckbox,
    CtTooltip
  },

  methods: {
    clickHandler: function (e) {
      this.$emit('custom', e)
    },

    getNestedValue (fieldName, dataItem) {
      const path = fieldName.split('.')
      let data = dataItem
      path.forEach((p) => {
          data = data ? data[p] : undefined
      })
      return data
    },

    emitSelection (e) {
      this.$emit('selection', {
        selection: e,
        item: this.dataItem
      })
    },

    setContentStatus () {
      this.contentTruncated = this.$refs.cellContent.clientWidth < this.$refs.cellContent.scrollWidth
    }
  },

  data () {
    return {
      contentTruncated: false
    }
  },

  computed: {
    showSelectBox () {
      return this.selectAll && ((!this.expandField && this.columnIndex === 0) || (this.expandField && this.columnIndex === 1))
    },

    selected: {
      get () {
        return this.dataItem[this.selectedField]
      },
      set (val) {}
    },

    showTooltip: {
      get () {
        return this.isSpanTruncated
      },
      set (val) {}
    }
  }
}
</script>
<style lang="scss" scopped>
.v-input.v-input--selection-controls {
  margin-top: 0;
  padding-top: 0
}
.cell-content {
  overflow: hidden;
  white-space:nowrap;
  text-overflow:ellipsis;
}
</style>
