<template>
  <ct-menu v-model="menuOpen" bottom left :close-on-content-click="false">
    <template #activator="{ on: menu }">
      <ct-badge
        :content="selectedColumns.length"
        overlap
        :value="selectedColumns.length > 0">
        <ct-tooltip top>
          <template #activator="{ on: tooltip }">
            <ct-button class="filterBtn ml-6" color="secondaryBtn" v-on="Object.assign({}, tooltip, menu)" min-width="16">
              <ct-icon height="16px" width="14px" color="secondaryBtn">cticon-filter</ct-icon>
            </ct-button>
          </template>
          <span>Columns</span>
        </ct-tooltip>
      </ct-badge>
    </template>
    <div class="d-flex flex-column">
      <ct-list dense class="py-0">
        <ct-list-item-group multiple v-model="selectedColumns">
          <ct-list-item v-for="(item, index) in selectableColumns" :key="index" :value="item.field" :disabled="item.locked">
            <template #default="{ active }">
              <ct-list-item-action class="my-0">
                <ct-checkbox class="mt-0 pt-0" hide-details :input-value="active" :disabled="item.locked" />
              </ct-list-item-action>
              <ct-list-item-content>
                <slot name="listItem" :item="item">
                  <span class="t-14 blue-grey--text">{{item.title}}</span>
                </slot>
              </ct-list-item-content>
            </template>
          </ct-list-item>
        </ct-list-item-group>
      </ct-list>
    </div>
    <div class="multi-menu-footer d-flex align-center justify-space-between px-4 py-2">
      <div class="d-flex align-center">
        <ct-button @click="close" small color="negativeBtn" class="mr-2">Cancel</ct-button>
        <ct-button @click="handleColumnChange" small>Apply</ct-button>
      </div>
    </div>
  </ct-menu>
</template>
<script>

// components
import CtIcon from '../../Icon/CtIcon'
import CtMenu from '../../Menu/CtMenu'
import CtBadge from '../../Badge/CtBadge'
import CtButton from '../../Button/CtButton'
import CtTooltip from '../../Tooltip/CtTooltip'
import CtCheckbox from '../../Checkbox/CtCheckbox'
import { CtList, CtListItem, CtListItemContent, CtListItemGroup, CtListItemAction } from '../../List'
export default {
  name: 'CtColumnSelector',
  components: {
    CtIcon,
    CtMenu,
    CtBadge,
    CtButton,
    CtTooltip,
    CtCheckbox,
    CtList,
    CtListItem,
    CtListItemContent,
    CtListItemGroup,
    CtListItemAction
  },
  props: {
    columns: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      menuOpen: false,
      selectedColumns: []
    }
  },
  computed: {
    selectableColumns () {
      return this.columns.filter(c => c.field)
    }
  },
  mounted () {
    this.selectedColumns = this.getSelectedColumns()
  },
  methods: {
    handleColumnChange () {
      this.$emit('columnsChange', this.columns.map(c => {
        return this.selectedColumns.includes(c.field) ? { ...c, hidden: false } : { ...c, hidden: true }
      }))
      this.closeMenu()
    },
    close () {
      this.selectedColumns = this.getSelectedColumns()
      this.closeMenu()
    },
    closeMenu () {
      this.menuOpen = false
    },
    getSelectedColumns () {
      return this.columns.reduce((selectedCols, currentCol) => {
        if (!currentCol.hidden) {
          selectedCols.push(currentCol.field)
        }
        return selectedCols
      }, [])
    }
  }
}
</script>
<style scoped>
  .filterBtn {
    width: 36px!important;
    height: 36px!important;
    box-shadow: 0 2px 3px 0 rgba(78,84,108,0.15) !important;
    border-color: #126BFF
  }
  .multi-menu-footer {
    background-color: #fff;
    z-index: 2;
    position: sticky;
    bottom: 0;
    border-top: 1px solid #ECEDF2;
  }
  ::v-deep .v-list-item--disabled .v-list-item__content>span {
    color: rgba(0, 0, 0, 0.38) !important;
  }
</style>
