<template>
    <ct-menu bottom left>
      <template #activator="{on}">
        <ct-button small color="primary" dark :icon="icon" v-on="on">
          <slot>
            <ct-icon color="blue-grey lighten-2">ctleftnavicon-ellipsis_grey_icon</ct-icon>
          </slot>
        </ct-button>
      </template>

      <ct-list>
        <ct-list-item v-for="(item, index) in localList" :key="index" @click="itemSelected(index)" :disabled="!!item[disabledKey]">
          <ct-list-item-icon v-if="item[iconKey]" class="mr-0">
            <ct-icon :size="16" :disabled="!!item[disabledKey]">{{item[iconKey]}}</ct-icon>
          </ct-list-item-icon>
          <ct-list-item-title>{{item[labelKey]}}</ct-list-item-title>
        </ct-list-item>
      </ct-list>
    </ct-menu>
</template>

<script>
  import CtMenu from '../../Menu/CtMenu'
  import CtButton from '../../Button/CtButton'
  import { CtList, CtListItem, CtListItemIcon, CtListItemTitle } from '../../List'
  import CtIcon from '../../Icon/CtIcon'

  export default {
    name: 'CtMoreOptionsCell',
    components: {
      CtMenu,
      CtButton,
      CtList,
      CtListItem,
      CtListItemIcon,
      CtListItemTitle,
      CtIcon
    },
    props: {
      items: {
        type: Array,
        default: () => []
      },
      icon: {
        type: Boolean,
        default: true
      },
      labelKey: {
        type: String,
        default: 'label'
      },
      iconKey: {
        type: String,
        default: 'icon'
      },
      disabledKey: {
        type: String,
        default: 'disabled'
      }
    },
    computed: {
      localList () {
        return this.items.map(item => {
          if (typeof item === 'object') {
            return item
          }
          return {
            [this.labelKey]: item
          }
        })
      }
    },
    methods: {
      itemSelected (index) {
        const item = this.items[index]
        this.$emit('onItemClick', item)
      }
    }
  }
</script>

<style scoped>

</style>
