<script>
import { VBadge } from 'vuetify/lib'

let _VBadge = VBadge

export default {
  name: 'CtBadge',
  extends: _VBadge
}
</script>

<style>

</style>
