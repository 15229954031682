
<script>
import { Grid } from '@progress/kendo-vue-grid'
export default {
  extends: Grid,

  props: {
    pageable: {
      type: [Object, Boolean],
      default: () => ({
        buttonCount: 2,
        info: false,
        type: 'numeric',
        pageSizes: false,
        previousNext: true
      })
    }
  }
}
</script>
