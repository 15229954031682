<script>
import { VMenu } from 'vuetify/lib'

let _VMenu = VMenu

export default {
  name: 'CtMenu',
  extends: _VMenu
}
</script>

<style>

</style>
