<template>
  <div class="search-bar">
    <ct-row class="justify-space-between">
      <ct-col>
        <ct-textfield :placeholder="placeholder" v-model="searchInput" hide-details class="search-input" @input="handleSearch">
          <ct-icon class="mt-1" slot="prepend-inner" size="16">
            cticon-search
          </ct-icon>
        </ct-textfield>
      </ct-col>
      <ct-col class="d-flex justify-end">
        <ct-button @click="handleAddAction" color="primary"><span v-html="actionButtonHTML"></span></ct-button>
      </ct-col>
    </ct-row>
  </div>
</template>
<script>
import CtTextfield from '../../Textfield/CtTextfield'
import CtButton from '../../Button/CtButton'
import CtCol from '../../Grid/GridSystem/CtCol'
import CtRow from '../../Grid/GridSystem/CtRow'
import CtIcon from '../../Icon/CtIcon'
export default {
  props: {
    actionButtonHTML: String,
    value: String,
    placeholder: {
      type: String,
      default: 'Search'
    }
  },

  components: {
    CtTextfield,
    CtRow,
    CtCol,
    CtButton,
    CtIcon
  },

  data () {
    return {
      searchInput: ''
    }
  },

  methods: {
    handleSearch (e) {
      this.$emit('searchText', e)
      this.$emit('input', this.searchInput)
    },

    handleAddAction (e) {
      this.$emit('addAction', e)
      this.searchInput = ''
    }
  },

  watch: {
    value (newValue, oldValue) {
      this.searchInput = newValue
    }
  },

  mounted () {
    this.searchInput = this.value
  }
}
</script>
<style lang="scss" scopped>
.search-bar {
  width: 100%;
}
.search-input {
  width: 240px;
}
</style>
