<template>
  <div class="ct-page-size-selector d-flex justify-center align-center mr-2">
    <div class="label mr-2">Show</div>
    <ct-select :items="pagerSteps" v-model="selectModel" hide-details dense @change="emitStep"></ct-select>
  </div>
</template>
<script>
import CtSelect from '../../Select/CtSelect'
export default {
  components: { CtSelect },

  props: {
    pageSize: {
      type: Number,
      default: 10
    },

    pagerSteps: {
      type: Array,
      default: () => ([
        { text: '5 rows', value: 5 },
        { text: '10 rows', value: 10 },
        { text: '20 rows', value: 20 }
        ])
    }
  },

  data () {
    return {
      selectModel: 20
    }
  },

  methods: {
    emitStep (e) {
      this.$emit('changePageSize', e)
    }
  },

  mounted () {
    this.selectModel = this.pageSize
  },

  watch: {
    pageSize (to) {
      this.selectModel = to
    }
  }

}
</script>
<style lang="scss" scoped>
  .ct-page-size-selector {
    width: 160px;
  }
</style>
